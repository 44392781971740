<template>
  <div class="watchlistStatsContainer">
    <div class="watchlist">
      <el-avatar
        :size="60"
        :src="
          watchlistData.watchListArtists.length
            ? watchlistData.watchListArtists[0].artist.imageUrl
            : '/media/avatars/blank.png'
        "
      ></el-avatar>
      <div class="name">
        <h2 class="heading watchlist-name">{{ watchlistData.name }}</h2>
      </div>
    </div>
    <div>
      <div class="followers-container">
        <div
          class="
            border border-gray-300 border-dashed
            rounded
            min-w-125px
            d-flex
            flex-column
            align-items-center
            py-3
            px-4
            me-6
            mb-3
          "
        >
          <div class="d-flex align-items-center">
            <div class="fs-2 fw-bolder">
              {{ formatNumber(watchlistData.totalSpotifyListeners) }}
            </div>
          </div>
          <div class="fw-bold fs-6 text-gray-400 text">
            Total Monthly Listeners
          </div>
        </div>
        <div
          class="
            border border-gray-300 border-dashed
            rounded
            min-w-125px
            d-flex
            flex-column
            align-items-center
            py-3
            px-4
            me-6
            mb-3
          "
        >
          <div class="d-flex align-items-center">
            <div class="fs-2 fw-bolder">
              {{ formatNumber(watchlistData.totalFollowers) }}
            </div>
          </div>
          <div class="fw-bold fs-6 text-gray-400">Total Fanbase</div>
        </div>
      </div>
    </div>
    <div>
      <router-link
        :to="`/watchlist/${$route.params.id}/forecast`"
        class="btn btn-sm btn-primary me-3"
        >Forecast
      </router-link>
      <button
        class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="top-end"
      >
        <i class="bi bi-three-dots fs-3"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { useNumberFormatter } from "@/common/composables/useNumberFormatter";

export default {
  name: "WatchlistStats",
  props: {
    watchlistData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { formatNumber } = useNumberFormatter();
    return { formatNumber };
  },
};
</script>

<style scoped>
.watchlistStatsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 700px) {
  .watchlistStatsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .followers-container {
    display: flex;
    flex-wrap: wrap;
  }

  .watchlist-name {
    margin-left: 1rem;
  }

  .watchlist {
    display: flex;
    align-items: center;
  }
}
</style>
