<template>
  <div class="table-container" v-loading="loading">
    <table
      class="table table-row-bordered table-row-gray-300 gy-7 watchlist-table"
    >
      <thead>
        <tr class="fw-bolder fs-6 text-gray-800 text-center">
          <th class="hidden-sm-and-down"></th>
          <th>ARTIST</th>
          <th>MONTHLY LISTENERS</th>
          <th>YOUTUBE VIEWS</th>
          <th>SIZE OF FANBASE</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(artistData, index) in watchlistData.watchListArtists"
          :key="index"
          class="text-center"
        >
          <td class="table-data hidden-sm-and-down">
            <el-avatar
              class="cursor-pointer"
              @click="openArtistProfileDrawer(artistData.artist.uuid)"
              :size="35"
              :src="artistData.artist.imageUrl"
            ></el-avatar>
          </td>
          <td class="table-data">
            <span
              class="cursor-pointer"
              @click="openArtistProfileDrawer(artistData.artist.uuid)"
              >{{ artistData.artist.name }}</span
            >
          </td>
          <td class="table-data">
            {{ formatNumber(artistData.artist.totalSpotifyListeners) }}
          </td>
          <td class="table-data">
            {{ formatNumber(artistData.artist.totalYoutubeViews) }}
          </td>
          <td class="table-data">
            {{ formatNumber(artistData.artist.totalFollowers) }}
          </td>
          <td class="table-data delete-artist">
            <el-dropdown trigger="click" placement="top">
              <button
                class="
                  btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  el-dropdown-link
                "
              >
                <i class="bi bi-three-dots fs-3"></i>
              </button>
              <template #dropdown>
                <el-dropdown-menu placement="top">
                  <el-dropdown-item
                    @click="deleteArtist(artistData.artist.uuid)"
                    >Delete Artist</el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import { useNumberFormatter } from "@/common/composables/useNumberFormatter";

export default {
  name: "WatchlistDetailTable",
  props: {
    watchlistData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { formatNumber } = useNumberFormatter();
    const store = useStore();
    const route = useRoute();
    const loading = ref(false);

    const deleteArtist = async (artistID) => {
      try {
        loading.value = true;
        await store.dispatch("UserModule/deleteArtistFromWatchlist", {
          artistID: artistID,
          watchlistID: Number(route.params.id),
        });
      } catch (e) {
        console.error(e);
      } finally {
        loading.value = false;
      }
    };

    return { formatNumber, deleteArtist, loading };
  },
};
</script>

<style scoped>
.table-data {
  vertical-align: middle;
}
.table-container {
  overflow-x: scroll;
}
</style>
