<template>
  <div class="card mb-5 mb-xl-10 py-10 px-10" v-if="watchlistData">
    <WatchlistStats :watchlist-data="watchlistData" />
    <WatchlistDetailTable
      v-if="watchlistData.watchListArtists.length"
      :watchlist-data="watchlistData"
    />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { computed } from "vue";

import WatchlistStats from "../components/WatchlistStats";
import WatchlistDetailTable from "../components/WatchlistDetailTable";

export default {
  components: { WatchlistStats, WatchlistDetailTable },

  setup() {
    const store = useStore();
    const route = useRoute();

    const watchlistData = computed(() =>
      store.getters["UserModule/getWatchlistById"](Number(route.params.id))
    );
    return { watchlistData };
  },
};
</script>

<style lang="scss" scoped></style>
